<template>
    <div class="query-filtering">
        <div
            class="filtering-icon"
            id="J_filtering"
            ref="filteringIcon"
            @click="showTreeList">
            <img
                class="filter_img"
                :src="!showTree ? filteringImg : activefilteringImg"
                alt="filter"
            />
        </div>
        <transition name="fade">
            <div
                v-if="showTree"
                id="J_customized-filters"
                class="tree-list"
                :style="{
                    top: treeTop,
                    left: treeLeft,
                }">
                <p class="title">自定义筛选项</p>
                <el-input
                    v-model="filterText"
                    class="filter-content"
                    prefix-icon="el-icon-search"
                    placeholder="请输入关键词"
                    @input="handlerSearch"
                ></el-input>
                <div class="tree-wrapper">
                    <el-checkbox
                        v-for="(item, index) in renderData"
                        :key="index + item.isOff"
                        :class="{ 'unchecked': item.isOff != '1' }"
                        :checked="item.isOff == '1'"
                        @change="handlerItemCheck($event, item)"
                    >{{ item.conditionName }}</el-checkbox>
                </div>
                <div class="operation">
                    <div class="checkbox">
                        <el-checkbox
                            v-model="isAllChecked"
                            :indeterminate="isIndeterminate"
                            style="font-size: 14px !important;"
                            @change="handlerAllCheck"
                        >全选</el-checkbox>
                    </div>
                    <div>
                        <el-button
                            type="text"
                            class="primary"
                            @click="handlerReset"
                        >重置</el-button>
                        <el-button
                            type="primary"
                            style="width: 48px;height:24px; padding: 0px 6px !important; font-size: 12px !important;"
                            @click="handlerConfirm"
                        >确定</el-button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { debounce } from 'common-local'

export default {
    name: 'QueryFiltering',
    components: {},
    props: {
        treeScreeningData: {
			type: Array,
		},
    },
    data() {
        return {
            showTree: false,
            treeLeft: "",
            treeTop: "",
            filterText: '', // 搜索文本
            logicData: null, // 逻辑数据
            renderData: null, // 渲染数据
            isAllChecked: false, // 全选
            isIndeterminate: false // 半选
        }
    },
    computed: {
        filteringImg() {
            return require("@/assets/images/filter_info.png");
        },
        activefilteringImg() {
            return require("@/assets/images/filter_active.png");
        },
    },
    watch: {},
    created() {
        this.$on('handlerGlobalClick', (e) => {
            this.handlerGlobalClick(e)
        })
        // this.init();
    },
    mounted() {
        this.bindEvent();
    },
    methods: {
        /**
         * @Description: 全局鼠标点击事件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-17 15:38:06
         */
        handlerGlobalClick (e) {
            const customizedFilters = document.querySelector('#J_customized-filters');
            const filtering = document.querySelector('#J_filtering');
            let _this = this;

            if (customizedFilters && !customizedFilters.contains(e.target) && filtering && !filtering.contains(e.target)) {
                _this.showTree = false;
            }
        },
        // 初始化入口
        init() {
            console.log("[init]this.treeScreeningData:", this.treeScreeningData)
            this.filterText = ''
            this.logicData = this._.cloneDeep(this.treeScreeningData);
            this.renderData = this._.cloneDeep(this.logicData)
            this.getAllCheckAndIndeterminate()
        },

        /**
         * @Description: 绑定事件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-05 09:07:19
         */
        bindEvent() {
            this.bindResize();
        },
        bindResize() {
            let _this = this;
            // window.onresize = function () {
                if (_this.showTree) {
                    _this.getTreeAdr();
                }
            // };
        },
        /**
         * @Description: 打开弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-04 15:52:53
         */
        showTreeList() {
            if (this.showTree) {
                this.showTree = false;
                return;
            }

            this.getTreeAdr();

            this.$nextTick(() => {
                this.init()
                this.showTree = true;
            });
        },
        /**
         * @Description: 获取元素
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-04 19:08:12
         */
        getTreeAdr() {
            this.treeTop = '';
            this.treeLeft = '';
            let filtering = document.getElementById("J_filtering");
            let top = this.offsetTop(filtering);
            let offsetLeft = this.offsetLeft(filtering);
            // 200 - 36(自身的宽高)
            this.treeTop = top + 40 + "px";
            this.treeLeft = offsetLeft - 178 + "px";
        },
        offsetTop(elements) {
            let top = elements.offsetTop;
            let parent = elements.offsetParent;
            while (parent != null) {
                top += parent.offsetTop;
                parent = parent.offsetParent;
            }
            return top;
        },
        offsetLeft(elements) {
            let left = elements.offsetLeft;
            let parent = elements.offsetParent;
            while (parent != null) {
                left += parent.offsetLeft;
                parent = parent.offsetParent;
            }

            return left;
        },

        // 搜索
        handlerSearch: debounce(function () {
            if (this.filterText) {
                this.renderData = this.renderData.filter(e => e.conditionName.indexOf(this.filterText) > -1)
            } else {
                this.renderData = this._.cloneDeep(this.logicData)
            }
            this.getAllCheckAndIndeterminate()
        }, 200),
        // 计算全选半选
        getAllCheckAndIndeterminate() {
            let checkedNum = this.renderData.filter(e => e.isOff == '1').length
            this.isAllChecked = checkedNum == this.renderData.length
            this.isIndeterminate = checkedNum > 0 && checkedNum < this.renderData.length
            this.$forceUpdate();
        },
        // 单项勾选或取消勾选
        handlerItemCheck(check, item) {
            // console.log("[handlerChange]", check, item)
            check = check ? '1' : '0'
            // 修改逻辑数据
            let find = this.logicData.find(e => e.conditionId === item.conditionId)
            if (find) find.isOff = check
            // 修改渲染数据
            item.isOff = check
            // 计算全选半选
            this.getAllCheckAndIndeterminate()
        },
        // 全选
        handlerAllCheck(check) {
            check = check ? '1' : '0'
            for (let item of this.renderData) {
                // 修改渲染数据
                let find = this.logicData.find(e => e.conditionId === item.conditionId)
                if (find) find.isOff = check
                // 修改渲染数据
                item.isOff = check
            }
            // 计算全选半选
            this.getAllCheckAndIndeterminate()
        },
        // 重置
        handlerReset() {
            this.init()
        },
        // 确定
        handlerConfirm() {
            // console.log("[handlerConfirm]this.logicData:", this._.cloneDeep(this.logicData))
            let checkedData = this.logicData.filter(e => e.isOff == '1')
            // console.log("[handlerConfirm]this.logicData:", checkedData)
            this.$emit("determine", checkedData);
            this.showTree = false;
            this.filterText = ''
        }
    }
}
</script>

<style lang="scss" scoped>
.filter_img {
    width: 32px;
    height: 32px;

    &:hover {
        content: url("~@/assets/images/filter_active.png");
    }
}

.query-filtering {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    position: relative;

    .filtering-icon {
        width: 36px;
        height: 36px;
        cursor: pointer;
    }

    .tree-list {
        width: 210px;
        position: fixed;
        z-index: 9999;
        box-sizing: border-box;
        padding: 12px 6px 0px;
        background-color: #fff;
        box-shadow: 0px 6px 24px 0px #d9d9d9;
        border-radius: 6px;

        .title {
            font-size: 15px;
            line-height: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2b2f33;
            padding-left: 7px;
        }

        .filter-content {
            margin-top: 8px;
            height: 32px;
            border-bottom: 1px solid #edf0f2;

            ::v-deep .el-input__inner {
                background-color: #fff;
                border: none !important;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2B2F33;
            }

            ::v-deep .el-input__inner::placeholder {
                color: #bbbdbf;
            }

            ::v-deep .el-input__prefix {
                top: 2px !important;
            }
        }

        ::v-deep .el-checkbox{
            padding: 0 10px;
            box-sizing: border-box;
            height: 32px;
            line-height: 32px;
            width: 100%;
            margin-right: 0;

            .el-checkbox__input.is-checked .el-checkbox__inner {
                background-color: #3C7FFF;
                border-color: #3C7FFF;
                border-radius: 3px !important;
                overflow: hidden;
            }

            .el-checkbox__label {
                font-size: 14px !important;
            }
            .el-checkbox__label.is-checked {
                color: #3C7FFF;
            }
            .el-checkbox__label.unchecked {
                color: #2B2F33;
            }

            &:hover{
                background: #E6E8EB;
            }
        }

        .tree-wrapper {
            height: 210px;
            background-color: #f7f7f7;
            border-radius: 4px;
            margin-top: 8px;
            overflow: auto;

            ::v-deep .el-tree {
                background-color: #f7f7f7;
            }
        }

        .operation {
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .checkbox {
                ::v-deep .el-checkbox {
                    width: auto;
                }
                ::v-deep .el-checkbox__label {
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
