<template>
    <div class="flexLink">
        <div v-if="!url"></div>
        <iframe v-else :src="url" frameborder="0" scrolling="auto" width="100%" height="100%"></iframe>
    </div>
</template>

<script>
export default {
    name: "LinkIframe",
    props: {
        url: {
            type: String,
            default: "",
        },
    }
}
</script>

<style lang="scss" scoped>
.flexLink {
    height: calc(100vh - 116px);
}
</style>