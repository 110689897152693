<template>
    <div class="page-back-tabs">
        | tab1 tab2
    </div>
</template>
<script>
export default {
    name:"PageBackTabs"
}
</script>
<style lang="scss" scoped>
    .page-back-tabs {
        margin-left: 27px;
    }
</style>
